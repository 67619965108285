// 成员列表信息的
import request from '@/network/request.js'
/**
 * 获取没有权限的列表信息
 */
export function memberlists(data) {
	return request({
		url: '/cadmin/api.script_examine/lists',
		method: 'post',
		data,
	})
}

/**
 * 获取没有角色的用户
 */
export function arole_user_list(data) {
	return request({
		url: '/cadmin/api.system_user/arole_user_list',
		method: 'post',
		data,
	})
}

/**
 *角色成员添加用户
 */
export function role_add_user(data) {
	return request({
		url: '/admin/user/addroles',
		method: 'post',
		data,
	})
}


/**
 *批量解除用户角色
 */
export function relieverole(data) {
	return request({
		url: '/admin/user/relieverole',
		method: 'post',
		data,
	})
}



